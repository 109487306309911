.wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background-color: #f5f5f5;
    flex-direction: column;
}

.logo-image{
    width: 200px;
}