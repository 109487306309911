@import "../pages/CreditCardCapture/CreditCardCapture.scss";
@import "../components/CollapsableCard/CollapsableCard.scss";
@import "../components/Dropdown/Dropdown.scss";
@import "../components/Header/Header.scss";
@import "../components/NotFound/NotFound.scss";
@import "../pages/SuccessfulAuthorization/SuccessfulAuthorization.scss";
@import "../components/Toastr/ToastrComponent.scss";
@import "../../node_modules/react-toastify/dist/ReactToastify";
@import "../pages/CreditCardCapture/Transactions.scss";

html,
body {
  overflow-x: hidden;
  background-color: #f6f7fa;
  font-family: "proxima-nova", sans-serif !important;
  width: 100%;
  margin: 0px;
}

[data-amplify-authenticator] {
  font-family: "proxima-nova", sans-serif !important;
}

#root,
.app {
  height: 100%;
  font-family: "proxima-nova", sans-serif !important;
}
