.subHeader-container {
  width: 100%;
  height: 300px;
  background-color: #202945;
  margin-top: -20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-container {
  width: 200px;
  height: 200px;
  border: solid 6px #fc671a;
  background-color: #f6f7fa;
  border-radius: 50%;
}
.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-self: center;
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 1000px;
  height: 213px;
  margin-top: 64px;
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
}

.thank {
  margin: 0px;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-family: "proxima-nova", sans-serif !important;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #202945;
}

.thank-description {
  margin: 16px 0 24px;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #202945;
}
