.creditCard-main-container {
  justify-content: center;
  font-family: "proxima-nova", sans-serif !important;
}
.creditCard-form-container {
  font-family: "proxima-nova", sans-serif !important;
  margin: 50px;
  width: 500px;
  padding: 50px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
}

.credit-card-capture-input {
  font-family: "proxima-nova", sans-serif !important;
  width: 100%;
  margin: 5px 0px 20px 0px !important;
  .placeholder-style::placeholder {
    font-family: "proxima-nova", sans-serif !important;
    font-weight: 500;
  }
}

.credit-card-capture-expiration {
  width: 100%;
  margin: 5px 48px 20px 0px !important;
  .placeholder-style::placeholder {
    font-family: "proxima-nova", sans-serif !important;
    font-weight: 500;
  }
}
.css-z0zarm {
  font-family: "proxima-nova", sans-serif !important;
}

.credit-card-capture-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 49px;
  margin-top: 20px;
  align-items: baseline;
}

.credit-card-capture-helper-text {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.creditCard-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-family: "proxima-nova", sans-serif !important;
  column-gap: 64px;

  @media only screen and (max-width: 1300px) {
    grid-template-columns: initial;
    grid-template-rows: 1fr 1fr;
  }

  .rccs {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    .rccs__card {
      width: 100%;
      height: 100%;
      max-width: 357px;
      max-height: 232px;
      align-self: center;
    }
  }
}

.btn {
  font-size: 16px !important;
  font-family: "proxima-nova", sans-serif !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
  border-radius: 10px !important;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
}

.submit-action-btn {
  width: 320px;
  height: 48px;
  margin: 0 0 0 32px;
  padding: 14px 0;
  background-color: #ff5100 !important;
  box-shadow: none !important;
}

.cancel-action-btn {
  width: 320px;
  height: 48px;
  margin: 0 32px 0 0;
  padding: 14px 0;
  border: solid 2px #fc671a !important;
  background-color: white;
  color: #080f12 !important;
}

.checkbox-same-info {
  color: rgba(0, 0, 0, 0.54);
  margin-bottom: 20px;
  font-family: "proxima-nova", sans-serif !important;
  .css-ahj2mt-MuiTypography-root {
    font-family: "proxima-nova", sans-serif !important;
  }
}

.modal-div {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 350px;
  height: 245px;
  padding: 48px 0;
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
}

.spinner-title {
  margin: 24px 0px 0px 0px;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #202945;
}
.spinner-description {
  margin: 16px 0px 0px 0px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #202945;
}
