.toastr-container {
  .Toastify__toast-container {
    width: 400px;
    .Toastify__toast {
      border-radius: 5px;
      color: #242A36;
      &.Toastify__toast--error {
        background-color: #f9dadc;
      }
    }
  }
}
