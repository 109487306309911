.transactionCollapsiblePanel {
  padding: 8px 48px;

  .transactionResponse {
    padding-top: 8px;
    small {
      font-weight: 700;
    }
  }

  table.transactionResponseTable {
    td {
      border: 1px solid rgba(0, 0, 0, 0.16);
      padding: 8px;
      pre {
        text-wrap: pretty;
      }
    }
  }
}

.filterInput {
  div {
    padding: 0;
  }
  input {
    padding: 0 16px;
  }
  .clearButton {
    color: rgba(0, 0, 0, 0.16);
  }
  .clearButton:hover {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.87);
  }
}