.country-dropdown {
  font-family: "proxima-nova", sans-serif !important;

  .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
    font-size: 2em;
    color: #000000;
  }
  .css-bpeome-MuiSvgIcon-root-MuiSelect-icon {
    font-size: 2em;
    color: #000000;
  }
}
.css-6hp17o-MuiList-root-MuiMenu-list {
  font-family: "proxima-nova", sans-serif !important;
  max-height: 250px;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  font-family: "proxima-nova", sans-serif !important;
}

.css-1ka5eyc-MuiPaper-root-MuiMenu-paper-MuiPopover-paper {
  font-family: "proxima-nova", sans-serif !important;
}

.label {
  font-family: "proxima-nova", sans-serif !important;
  font-weight: 700px;
}
