.paper-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.card-container {
  width: 70%;
  margin: 12px 0px;
  padding: 24px 64px;
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  .css-1rrzeaj-MuiTypography-root {
    font-family: "proxima-nova", sans-serif !important;
  }
}

.section-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 64px;
  font-family: "proxima-nova", sans-serif !important;
}

.card-button.card-button {
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transform: rotate(180deg);
  
  &_rotated {
    transform: rotate(0deg);
  }
}