.header-container {
  margin-bottom: 20px;
  background-color: #ffffff;
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.header-image {
  margin-left: 64px;
  width: 181px;
  height: 62px;
}

.name-signOut {
  margin-right: 64px;;
}

.btn-sign-out{
  background-color: #ff5100 !important;
  color: #ffffff !important;
  border: 1px solid #ff5100 !important;
}

.btn-transactions{
  background-color: #ffffff !important;
  color: #ff5100 !important;
  border: 1px solid #ff5100 !important;
}

.btn {
  padding: 14px 0;
  box-shadow: none !important;
  margin-left: 24px !important;
}